import gammaStore from "@/store/gammaStore";

const AbsoluteMove = 0;
const RelativeMove = 1;
const RelativeZoom = 2;
const GoToHome = 3;
const SetHome = 4;
const ResetHome = 5;
const SavePreset = 6;
const RemovePreset = 7;

function SmartSuitePTZ(device) {
    this.gamma = null;
    this.device = device;
    this.ptzStatus = null;
    this.zoomPosition = null;
    this.presets = null;
    this.pauseValueSeconds = 60;
}

SmartSuitePTZ.prototype.connect = async function () {
    this.gamma = await gammaStore.dispatch('getPTZhub', this.device.getTenantId());
};

SmartSuitePTZ.prototype.close = async function () {
    await gammaStore.dispatch('closePTZhub', this.device.getTenantId());
};

SmartSuitePTZ.prototype.getPTZStatus = function () {
    return this.ptzStatus;
}

SmartSuitePTZ.prototype.getZoomPosition = function () {
    return this.zoomPosition;
}

SmartSuitePTZ.prototype.getPresets = function () {
    return this.presets;
}

SmartSuitePTZ.prototype.addSourceListener = function () {
    try {
        this.gamma.sendMessage('addtogroup', this.device.getFirstDataChannel());
    } catch (e) {
        console.error(e);
    }
}

SmartSuitePTZ.prototype.removeSourceListener = function () {
    try {
        this.gamma.sendMessage('removefromgroup', this.device.getFirstDataChannel());
    } catch (e) {
        console.error(e);
    }
}

SmartSuitePTZ.prototype.newCommand = async function (commandType) {
    return {
        PtzCommandType: commandType,
        SerialNumber: this.device.getSerialNumber(),
        PtzCommandChannel: this.device.getHubName() + "_ptzcommand",
        DataChannel: this.device.returnDataChannel(),
        StreamChannel: this.device.getFirstDataChannel(),
        ClientConnectionId: this.gamma.getConnectionId(),
        PtzGoHome: this.pauseValueSeconds <= 60,
        PtzGoHomeOverrideSeconds: this.pauseValueSeconds
    };
};

SmartSuitePTZ.prototype.sendCommand = function (command) {
    this.gamma.sendMessage('sendptzcommand', command);
};

SmartSuitePTZ.prototype.zoom = async function (newPosition) {
    await this.checkConnection();
    let command = await this.newCommand(RelativeZoom);
    command.ZoomSetting = newPosition;
    this.sendCommand(command);
};

SmartSuitePTZ.prototype.panTilt = async function (x, y) {
    //if timeSeconds any value other > 60 it is pausing auto-home, if unpausing set 60, if just moving PTZ ignore
    await this.checkConnection();
    let command = await this.newCommand(RelativeMove);
    command.PanTiltXSetting = x;
    command.PanTiltYSetting = y;
    this.sendCommand(command);
};

SmartSuitePTZ.prototype.absoluteMove = async function (x, y) {
    await this.checkConnection();
    let command = await this.newCommand(AbsoluteMove);
    command.PanTiltXSetting = x;
    command.PanTiltYSetting = y;
    this.sendCommand(command);
};

SmartSuitePTZ.prototype.clickMove = async function (x, y, space) {
    await this.checkConnection();
    let command = await this.newCommand(RelativeMove);
    command.PanTiltXSetting = x;
    command.PanTiltYSetting = y;
    command.PanTiltSpace = space;
    this.sendCommand(command);
};

SmartSuitePTZ.prototype.setPreset = async function (presetName) {
    await this.checkConnection();
    let command = await this.newCommand(SavePreset);
    command.PresetName = presetName;
    this.sendCommand(command);
};

SmartSuitePTZ.prototype.removePreset = async function (presetName) {
    await this.checkConnection();
    let command = await this.newCommand(RemovePreset);
    command.PresetName = presetName;
    this.sendCommand(command);
};

SmartSuitePTZ.prototype.setHome = async function () {
    await this.checkConnection();
    let command = await this.newCommand(SetHome);
    this.sendCommand(command);
}

SmartSuitePTZ.prototype.resetHome = async function () {
    await this.checkConnection();
    let command = await this.newCommand(ResetHome);
    this.sendCommand(command);
};

SmartSuitePTZ.prototype.goToHome = async function () {
    //this command has the PTZ immediately go back to its home position
    await this.checkConnection();
    let command = await this.newCommand(GoToHome);
    this.sendCommand(command);
};

SmartSuitePTZ.prototype.pauseGoHome = async function (seconds) {
    await this.checkConnection();
    await this.setPauseValueSeconds(seconds);
    await this.panTilt(0, 0);
};

SmartSuitePTZ.prototype.unpauseGoHome = async function () {
    await this.checkConnection();
    await this.setPauseValueSeconds(60);
    await this.panTilt(0, 0);
};

SmartSuitePTZ.prototype.setPTZStatusListener = async function (callback) {
    gammaStore.dispatch('setPTZhubListener', {
        _id: this.device.getTenantId(), event: 'ptzstatusupdate', callback: async (event) => {
            if (event !== null) {
                this.ptzStatus = event;
                this.zoomPosition = Math.floor((event.zoomPositionX / event.zoomLimitMax) * 100);
                this.presets = event.presets.map((preset, index) => {
                    return {text: preset.presetName, value: index};
                });
                callback(event);
            }
        }
    });
}

SmartSuitePTZ.prototype.setPauseValueSeconds = async function (seconds) {
    this.pauseValueSeconds = seconds;
}

SmartSuitePTZ.prototype.checkConnection = async function () {
    if (!this.gamma.isConnected()) {
        await this.connect();
    }
};
export default SmartSuitePTZ;