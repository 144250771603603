import authStore from "@/store/authStore";

let signalr = require('@microsoft/signalr');
import urlJoin from 'url-join';
import PubSub from "pubsub-js";
import deviceStore from "../store/deviceStore";

function Gamma() {
    this.connection = null;
    this.listeners = {};
    this.endpoint = null;
    this.url = null;
}

Gamma.prototype.connect = async function(url, endpoint) {
    this.endpoint = endpoint;
    this.url = url;
    this.connection = new signalr.HubConnectionBuilder()
        .withUrl(urlJoin(url, endpoint), {
            withCredentials: false,
            accessTokenFactory: () => authStore.getters.getUserManager.getAccessToken()
        })
        .configureLogging(signalr.LogLevel.None)
        .withAutomaticReconnect()
        .build();
    try {
        await this.connection.start();
        PubSub.publish('connected' + endpoint);
    } catch (e) {
        //console.error("Error Connecting to: " + url + " | " + endpoint, e);
    }
    this.connection.onclose(e => {
        //console.log(e);
    });
    this.connection.onreconnected(id => {
        //console.log("Gamma Reconnected: " + id, 'Endpoint: '+endpoint);
    });
    this.connection.on('hubNameChannel', async (edgeDevice) => {
        //this listens for the edge services that a user has access to based on their tenant permissions, these are
        //streamed back one by one, not as an array in format 'edgeName|friendlyName'
        let temp = await authStore.getters.getHomeTenantId;
        edgeDevice = edgeDevice.split('|');
        await deviceStore.dispatch('updateEdgeDevices', {edgeDevice: {name: edgeDevice[0], friendlyName: edgeDevice[1]}, _id: temp});
    })
}

Gamma.prototype.close = async function() {
    await this.connection.stop();
}

Gamma.prototype.refresh = async function(flipBool) {
    if (this.isConnected() && flipBool !== true) {
        await this.connection.stop();
        await this.connection.start().catch(e => {
            this.refresh();
        });
    } else if (flipBool === true && !this.isConnected() && !this.isConnecting()) {
        await this.connection.start();
    } else if (!this.isConnected() && !this.isConnecting() && !this.isDisconnecting()) {
        await this.connection.start();
    }
}

Gamma.prototype.isConnected = function () {
    return this.connection.state === signalr.HubConnectionState.Connected;
}

Gamma.prototype.isConnecting = function () {
    return this.connection.state === signalr.HubConnectionState.Connecting;
}

Gamma.prototype.isDisconnecting = function () {
    return this.connection.state === signalr.HubConnectionState.Disconnecting;
}

Gamma.prototype.isDisconnected = function () {
    return this.connection.state === signalr.HubConnectionState.Disconnected;
}

Gamma.prototype.setListener = function(type, callback) {
    this.listeners[type] = {callback: callback, open: true};
    this.connection.on(type, callback);
}

Gamma.prototype.stopListener = function (type) {
    this.connection.off(type, this.listeners[type].callback);
    this.listeners[type].open = false;
}

Gamma.prototype.stopListeners = function () {
    for (const type in this.listeners) {
        if (this.listeners[type].open === true) {
            this.connection.off(type, this.listeners[type].callback);
            this.listeners[type].open = false;
        }
    }
}

Gamma.prototype.sendMessage = function(method, ...args) {
    if (this.connection.state === signalr.HubConnectionState.Connected) {
        return (args !== undefined ? this.connection.invoke(method, ...args) : this.connection.invoke(method));
    } else if (this.connection.state === signalr.HubConnectionState.Connecting) {
        console.warn("Connection is still connecting, waiting 2 seconds to allow full connection", this.endpoint, this.url);
        setTimeout(() => {
            return this.sendMessage(method, ...args);
        }, 2000);
    } else {
        console.warn("Cannot send data if the connection is not in the 'Connected' State", this.connection.state, this.endpoint, this.url);
    }
}

Gamma.prototype.streamMessage = function(method, ...args) {
    return this.connection.stream(method, ...args);
}

Gamma.prototype.getConnectionId = function() {
    return this.connection.connectionId;
}

export default Gamma;