import deviceStore from "@/store/deviceStore";
import PubSub from 'pubsub-js';
import SmartSuiteLivestream from "../smartsuite_services/smartsuite_livestream";
import msgpack from './msgpack.min.js';
import JMuxer from 'jmuxer';

const External = 0;
const SdCard = 1;

function JmuxPlayer(device, element, quality, sourceToken, dataChannel, archiveOnly) {
    this.device = device;
    this.sourceToken = sourceToken;
    this.dataChannel = dataChannel;
    this.element = element;
    this.quality = quality;
    this.playerId = device.getDeviceId() + sourceToken + dataChannel;
    this.archiveOnly = archiveOnly;
    if (archiveOnly === true) {
        this.stream = {streamServer: device.getStreamServer()};
    } else if (!quality && quality !== 0) {
        this.stream = device.findQuality(device.getStreams(), deviceStore.getters.getQuality, device, sourceToken, dataChannel);
    } else {
        this.stream = device.findQuality(device.getStreams(), quality, device, sourceToken, dataChannel);
    }
    this.ws = null;
    this.jmuxer = null;
    this.currentTime = 0;
    this.mode = null;
    this.isContinuous = null;
    this.paused = false;
    this.watch_websocket = null;
    this.retryCount = 0;
    this.streamTimeoutTimerMS = 20000;
    this.sdCardFrameCount = 0;
    this.errorRetryCount = 0;
    this.ws_feeding = false;
    this.pastFrames = [];
    this.presentFrames = [];
    this.futureFrames = [];
    this.nextSegmentReady = false;
    this.firstQueueRequest = true;
    this.currentFrameBufferTime = null;
    this.feedFramesId = null;
    this.bufferTimeout = null;
    this.catchLastLoop = false;
    this.catchErrorLoop = false;
    this.scrubFramesId = null;
    this.playingForward = true;
    this.previousIFrameIndex = null;
    this.newRequest = true;
    this.selfDestructTimeout = null;
    this.firstPastRequest = true;
    this.firstFutureRequest = true;
    this.playbackSpeed = 1;
    this.segmentIndex = 0;
    this.livePlay = false;
    this.adaptive_stream_interval = null;
    this.retryConnectionInterval = null;
    this.queuedTimes = {
        past: {startTime: null, endTime: null},
        present: {startTime: null, endTime: null},
        future: {startTime: null, endTime: null}
    };
    this.sdCardFrames = [];
    this.buffering = false;
    this.storageLocation = null;
    this.isArchive = false;
    this.sdCardStartTime = null;
    this.fps15 = 66;
}

JmuxPlayer.prototype.new_jmuxer = function(stream, delay, clearBuffer, flushingTime, mode, request) {
    if (this.jmuxer === null) {
        this.jmuxer = new JMuxer({
            node: this.element,
            mode: (mode !== undefined ? mode : 'video'),
            clearBuffer: (clearBuffer !== undefined ? clearBuffer : true),
            flushingTime: (flushingTime !== undefined ? flushingTime : 0),
            fps: stream.frameRate || request.fps || 15,
            maxDelay: (delay !== undefined ? delay : 3000),
            //debug: true,
            onReady: () => {
                if (request !== undefined) {
                    if (request.sdCard === true) {
                        this.sdcard_listeners(request);
                    } else if (mode !== undefined) {
                        this.start_playback_listeners(mode, request.startTime, request.endTime, 0, request.segment);
                    }
                } else {
                    this.startListeners();
                }
            }
        });
        document.getElementById(this.element).srcObject = this.jmuxer.mediaSource.handle;
    } else {
        if (request !== undefined) {
            if (request.sdCard === true) {
                this.sdcard_listeners(request);
            } else if (mode !== undefined) {
                this.start_playback_listeners(mode, request.startTime, request.endTime, 0, request.segment);
            }
        } else {
            this.startListeners();
        }
    }
}

JmuxPlayer.prototype.sdcard_listeners = function(request) {
    let count = 0;
    let pFrame = 1, iFrame = 5, spsFrame = 7;
    this.ws.binaryType = 'arraybuffer';

    clearTimeout(this.watch_websocket);
    this.watch_websocket = setTimeout(async () => {
        this.retryCount++;
        this.device.setCanvasErrorMessage(this.playerId, 'Error: Failed to read from Sd Card.', 'Retry Count: ' + this.retryCount);
        this.removeLoading();
        await this.reset_jmuxer();
    }, this.streamTimeoutTimerMS);

    this.ws.addEventListener('open', () => {
        document.getElementById(this.element).play().catch(() => {});
    });

    this.ws.addEventListener('message', async (event) => {
        clearTimeout(this.watch_websocket);
        await this.setBuffering();
        this.removeLoading();
        //this stops spsFrames from counting towards the time elapsed
        let temp = new Uint8Array(event.data)[4] & 0x1F
        if (temp === pFrame || temp === iFrame) {
            count++
        }
        this.sdCardFrameCount++;
        /*if (temp === pFrame) {
            one++;
        } else if (temp === iFrame) {
            five++;
        } else if (temp === spsFrame) {
            seven++;
        }*/
        //console.log(event, new Uint8Array(event.data)[4] & 0x1F, one, five, seven, count*(1000/request.fps), count, this.sdCardFrameCount*(1000/request.fps), this.sdCardFrameCount, request.stopTime - request.startTime)
        //this.currentTime = (request.startTime * 1000) + this.sdCardFrameCount*(1000/request.fps);
        this.currentTime = (request.startTime * 1000) + count * (1000 / request.fps);
        this.jmuxer.feed({
            video: new Uint8Array(event.data)
        });
        //this.sdCardFrames.push(new Uint8Array(event.data));
        /*if (toggle === false) {
            this.feedSdFrames(0, request);
            toggle = true;
        }*/
    });

    this.ws.addEventListener('close', (e) => {
        if (!e.wasClean) {
            clearTimeout(this.watch_websocket);
            this.watch_websocket = setTimeout(() => {
                console.error("Socket closed, waiting to restart.");
                this.clearBuffering();
            }, this.streamTimeoutTimerMS);
        }
    });

    this.ws.addEventListener('error', (e) => {
        console.error('Socket Error', e);
        this.errorRetryCount++;
        this.device.setCanvasErrorMessage(this.playerId, 'Error: Failed to establish a connection.', 'Retry Count: ' + this.retryCount);
        clearTimeout(this.watch_websocket);
        this.watch_websocket = setTimeout(() => {
            console.error("Socket error, restarting.");
        }, this.streamTimeoutTimerMS);
    });
}

JmuxPlayer.prototype.feedSdFrames = function (index, request) {
    this.currentFrameBufferTime = null;
    let inc = index;
    this.feedFramesId = setInterval(() => {
        if (this.sdCardFrames[inc] !== undefined) {
            //this.currentTime = this.sdCardStartTime+(1000*inc);
            this.jmuxer.feed({
                video: this.sdCardFrames[inc],
                duration: Math.floor((1000 / request.fps) / this.playbackSpeed)
            });
            inc++;
        } else if (this.sdCardFrames.length < 3 || this.ws_feeding === true) {
            /*this catches the loop in case of internet issues*/
            console.warn('catch')
        }
        else {
            console.warn('ended')
            clearInterval(this.feedFramesId);
            this.feedFramesId = null;
            this.paused = true;
        }
    }, (1000 / request.fps) / this.playbackSpeed);
    this.paused = false;
}

JmuxPlayer.prototype.start_playback_listeners = function(mode, startTime, endTime, tense) {
    this.ws_feeding = true;
    this.ws.binaryType = 'arraybuffer';

    this.ws.addEventListener('open', () => {
        document.getElementById(this.element).play().catch(() => {});
    });

    if (tense === 1) {this.futureFrames = [];}
    else if (tense === -1) {this.pastFrames = [];}
    else {this.presentFrames = [];}

    if (tense === -1 && this.nextSegmentReady !== true) {this.nextSegmentReady = true;}
    clearTimeout(this.watch_websocket);
    this.watch_websocket = setTimeout(async () => {
        this.retryCount++;
        this.device.setCanvasErrorMessage(this.playerId, 'Error: Stream did not start in time.', 'Retry Count: ' + this.retryCount);
        await this.reset_jmuxer();
    }, this.streamTimeoutTimerMS);
    let startedFeedingFrames = false;
    clearTimeout(this.watch_websocket);
    this.ws.addEventListener('message', async (event) => {
        clearTimeout(this.watch_websocket);
        this.removeLoading();
        let decoded = msgpack.decode(new Uint8Array(event.data));
        //this holds all frames for scrubbing
        if (decoded[1] <= endTime) {
            if (tense === 1) {this.futureFrames.push(decoded);}
            else if (tense === -1) {this.pastFrames.push(decoded);}
            else {this.presentFrames.push(decoded);}
            if(decoded[1] > startTime && this.firstQueueRequest === true) {
                if (startedFeedingFrames === false) {
                    this.setTime(decoded[1]);
                }
                this.currentFrameBufferTime = decoded[1];
                startedFeedingFrames = true;
            }
        }
    });
    if (this.firstQueueRequest === true) {
        this.currentTime = startTime;
    }

    this.ws.addEventListener('close', (e) => {
        if (!e.wasClean) {
            clearTimeout(this.watch_websocket);
            this.watch_websocket = setTimeout(async () => {
                //console.warn("Socket closed, waiting to restart.", e);
                this.clearBuffering();
                await this.reset_jmuxer();
            }, this.streamTimeoutTimerMS);
        }
    });

    this.ws.addEventListener('error', (e) => {
        console.error('Socket Error', e);
        this.errorRetryCount++;
        this.device.setCanvasErrorMessage(this.playerId, 'Error: Failed to establish connection.', 'Retry Count: ' + this.errorRetryCount);
        clearTimeout(this.watch_websocket);
        this.watch_websocket = setTimeout(async () => {
            console.error("Socket error, restarting.");
            await this.reset_jmuxer();
        }, this.streamTimeoutTimerMS);
    });
}

JmuxPlayer.prototype.startListeners = function () {
    PubSub.publish('livestreamPlaying' + this.playerId);
    this.ws.binaryType = 'arraybuffer';
    clearTimeout(this.watch_websocket);
    this.watch_websocket = setTimeout(async () => {
        this.retryCount++;
        this.device.setCanvasErrorMessage(this.playerId, 'Error: Stream did not start in time.', 'Retry Count: ' + this.retryCount);
        await this.reset_jmuxer();
    }, this.streamTimeoutTimerMS);

    this.ws.addEventListener('open', () => {
        document.getElementById(this.element).play().catch(() => {});
    });

    this.ws.addEventListener('message', async (event) => {
        clearTimeout(this.watch_websocket);
        await this.setBuffering();
        this.removeLoading();
        this.jmuxer.feed({
            video: new Uint8Array(event.data)
            //duration: 40
        });
    });

    this.ws.addEventListener('close', (e) => {
        if (!e.wasClean) {
            clearTimeout(this.watch_websocket);
            this.watch_websocket = setTimeout(async () => {
                //console.warn("Socket closed, waiting to restart.", e);
                this.clearBuffering();
                await this.reset_jmuxer();
            }, this.streamTimeoutTimerMS);
        }
    });

    this.ws.addEventListener('error', (e) => {
        //console.error('Socket Error', e);
        this.errorRetryCount++;
        this.device.setCanvasErrorMessage(this.playerId, 'Error: Failed to establish connection.', 'Retry Count: ' + this.errorRetryCount);
        clearTimeout(this.watch_websocket);
        this.watch_websocket = setTimeout(async () => {
            console.error("Socket error, restarting.");
            await this.reset_jmuxer();
        }, this.streamTimeoutTimerMS);
    });
}

JmuxPlayer.prototype.reset_jmuxer = async function () {
    await this.destroyWebSocket();
    await this.destroyJmuxer();
    //We need to clear the interval here. Because at this point we might still be feeding frames into Jmuxer for playback.
    clearInterval(this.feedFramesId);
    this.feedFramesId = null;
    this.paused = true;
    await this.open_jmuxer(this.stream, true);
}

JmuxPlayer.prototype.removeLoading = function () {
    if (this.device.getCanvasLoadingStatus(this.playerId) === true) {
        this.device.setCanvasLoadingStatus(this.playerId, false);
    }
    this.device.setCanvasErrorMessage(this.playerId, '', '');
}

 JmuxPlayer.prototype.setBuffering = async function() {
     await this.clearBuffering();
     this.bufferTimeout = setTimeout(() => {
         if (this.paused === false && this.catchLastLoop === false && this.catchErrorLoop === false) {
             this.buffering = true;
             PubSub.publish('videoBuffering' + this.playerId, true);
         } else if (this.catchLastLoop !== false) {
             this.catchLastLoop = false;
         } else if (this.catchErrorLoop !== false) {
             this.catchErrorLoop = false;
         }
     }, 1000);
 }

 JmuxPlayer.prototype.clearBuffering = function () {
     clearTimeout(this.bufferTimeout);
     if (this.buffering === true || this.catchLastLoop === true || this.catchErrorLoop === true) {
         this.buffering = false;
         PubSub.publish('videoBuffering' + this.playerId, false);
     }
     if (this.catchLastLoop === true) {
         this.catchLastLoop = null;
     }
     if (this.catchErrorLoop === true) {
         this.catchErrorLoop = null;
     }
 }

 JmuxPlayer.prototype.setTime = function (time, increment, pausePlayback) {
     clearInterval(this.feedFramesId);
     this.feedFramesId = null;
     this.paused = true;
     if (this.scrubFramesId === null) {
         this.scrubFramesId = setTimeout(async () => {
             let pFrameIndex;
             if (increment !== undefined && increment !== 0 && this.playingForward === false) {
                 pFrameIndex = increment - 1;
             } else {
                 pFrameIndex = this.presentFrames.findIndex(pframe => {
                     return pframe[1] >= time;
                 });
             }
             let iFrameFound = false;
             let futureIFrame = false;
             if (pFrameIndex === -1) {
                 pFrameIndex = 0;
             }
             let iFrameIndex = pFrameIndex;
             if (this.presentFrames.length > 2) {
                 if (this.previousIFrameIndex === null) {
                     while (iFrameFound === false) {
                         //go backwards in the array until you find the nearest Iframe
                         if (futureIFrame === false) {
                             if (this.presentFrames[iFrameIndex][2] === true) {
                                 iFrameFound = true;
                             } else if (iFrameIndex === 0) {
                                 futureIFrame = true;
                                 iFrameIndex = pFrameIndex + 1;
                             } else {
                                 iFrameIndex--;
                             }
                         } else {
                             if (this.presentFrames[iFrameIndex][2] === true) {
                                 iFrameFound = true;
                             } else {
                                 iFrameIndex++;
                             }
                         }
                     }
                 } else {
                     iFrameIndex = this.previousIFrameIndex;
                 }
             }
             //this queues up the next previousIFrame so we have it before we need it
             if (this.playingForward === true) {
                 this.previousIFrameIndex = null;
             } else if (iFrameIndex !== 0 || iFrameIndex !== 1) {
                 let previousIframe = false;
                 let index = this.presentFrames[iFrameIndex - 1][2] === true ? iFrameIndex - 2 : iFrameIndex - 1;
                 if (index !== 0 && index !== -1) {
                     while (previousIframe === false) {
                         if (this.presentFrames[index][2] === true) {
                             this.previousIFrameIndex = index;
                             previousIframe = true;
                         }
                         index--;
                     }
                 } else {
                     index = this.pastFrames.length - 1;
                     while (previousIframe === false) {
                         if (this.pastFrames[index][2] === true) {
                             this.previousIFrameIndex = index;
                             previousIframe = true;
                         }
                         index--;
                     }
                 }
             }
             if (pFrameIndex !== -1 && iFrameIndex !== -1 && this.presentFrames[iFrameIndex + 1] !== undefined) {
                 this.currentTime = this.presentFrames[pFrameIndex][1];
                 if (iFrameIndex !== pFrameIndex) {
                     this.prefeedBackwardsFrames(pFrameIndex, iFrameIndex, async count => {
                         await this.feedFrames(pFrameIndex, count, iFrameIndex, pausePlayback);
                         this.scrubFramesId = null;
                     })
                 } else {
                     this.jmuxer.feed({
                         video: this.presentFrames[iFrameIndex][3],
                         duration: 1
                     });
                     this.jmuxer.feed({
                         video: this.presentFrames[iFrameIndex][4],
                         duration: 1,
                         compositionTimeOffset: this.newRequest === true ? 2000 : 0
                     });
                     if (this.newRequest === true) {
                         this.newRequest = false;
                     }
                     await this.feedFrames(pFrameIndex, 1, iFrameIndex, pausePlayback);
                     this.scrubFramesId = null;
                 }
             } else {
                 //this is a quick fix so the video continues playing until
                 //a more permanent solution is found, just make sure each segment starts with an iframe,
                 //or just make it really dumb and just feed whatever is there
                 //consistently before removing this -RL
                 this.scrubFramesId = null;
                 this.setTime(time);
             }
         }, 67);
     }
 }

 JmuxPlayer.prototype.destroyWebSocket = function (framesGrabbedBool) {
     clearTimeout(this.selfDestructTimeout);
     if (this.ws !== null) {
         this.ws.removeEventListener('close', this.playback_listener);
         this.ws.close(1000, "Closing.");
         if (framesGrabbedBool === true) {
             this.firstQueueRequest = false;
             if (this.firstFutureRequest === true) {
                 this.firstFutureRequest = false;
                 this.request_next_segment();
             } else if (this.firstFutureRequest === false && this.firstPastRequest === true) {
                 this.firstPastRequest = false;
                 this.request_previous_segment();
             }
         }
     }
 }

 JmuxPlayer.prototype.destroyJmuxer = async function () {
     if (this.jmuxer !== null) {
         await this.jmuxer.destroy();
         this.jmuxer = null;
     }
 }

 JmuxPlayer.prototype.open_jmuxer = async function (stream, retryBoolean) {
     this.selfDestructTimeout = setTimeout( async () => {
         //this timeout is used to rebuild jmuxer after an amount of time
         //to prevent a memory leak caused by jmuxer
         await this.reset_jmuxer(true);
     }, 7200000);
     this.catchLastLoop = true;
     if (retryBoolean !== true) {
         this.device.setCanvasErrorMessage(this.playerId, '', '');
     }
     this.device.setCanvasLoadingStatus(this.playerId, true);
     await this.createSSLiveStream(stream);
 }

 JmuxPlayer.prototype.prefeedBackwardsFrames = function (pFrameIndex, iFrameIndex, callback) {
     this.jmuxer.feed({
         video: this.presentFrames[iFrameIndex][3],
         duration: 1,
     });
     this.jmuxer.feed({
         video: this.presentFrames[iFrameIndex][4],
         duration: 1,
         compositionTimeOffset: this.newRequest === true ? 2000 : 0
     });
     if (this.newRequest === true) {this.newRequest = false;}
     let count = 2;
     for (let i = iFrameIndex + 1; i < pFrameIndex + 1; i++) {
         this.jmuxer.feed({
             video: this.presentFrames[i][4],
             duration: 1
         });
         count++;
         if (i === pFrameIndex) {
             callback(count);
         }
     }
 }

 JmuxPlayer.prototype.feedFrames = async function (index, extraFramesCount, iFrameIndex, pausePlayback) {
     let spsFrame = false;
     let toggle = true;
     let inc = index;
     this.currentFrameBufferTime = this.presentFrames[this.presentFrames.length - 1][1];
     this.feedFramesId = setInterval(async () => {
         if (this.paused === true) {
             this.paused = false;
         }
         if (this.presentFrames[inc] !== undefined) {
             this.removeLoading();
             await this.setBuffering();
             if (this.presentFrames[inc][2] === true) { //iFrame handling
                 if (this.playingForward === true) {
                     ({spsFrame, toggle} = this.handlePlayingForwardIFrame(inc, spsFrame, toggle));
                 } else {
                     this.handlePlayingBackwardIFrame(inc);
                 }
             } else {   //pFrame handling
                 this.currentTime = this.presentFrames[inc][1];
                 if (this.playingForward === true) {
                     this.handlePlayingForwardPFrame(inc);
                 } else {
                     this.handlePlayingBackwardPFrame(inc, iFrameIndex);
                 }
             }
             this.playingForward === true ? inc++ : inc--;
         } else if (this.presentFrames.length < 3) {
             /*this catches the loop in case of internet issues*/
             this.setQueueBuffering();
         } else {
             //this plays the next queued segment we have stored
             try {
                 this.setup_next_segment();
             } catch (e) {
                 console.error('failed to setup next segment', this.futureFrames, e);
             }
         }
         if (pausePlayback === true) {
             clearInterval(this.feedFramesId);
             this.feedFramesId = null;
             this.clearBuffering();
         }
     }, this.fps15 / this.playbackSpeed);
     this.paused = false;
 }

 JmuxPlayer.prototype.handlePlayingForwardIFrame = function (inc, spsFrame, toggle) {
     //Sets the time if it isn't the first Iframe or if the first frame to display is actually a Pframe
     if (spsFrame === true || this.presentFrames[0][2] === true) {
         this.currentTime = this.presentFrames[inc][1];
     }
     this.jmuxer.feed({
         video: this.presentFrames[inc][3],
         duration: 1
     });
     if (this.mode === 'both') {
         this.jmuxer.feed({
             video: this.presentFrames[inc][4],
             audio: this.presentFrames[inc][5]
         });
     } else {
         toggle = !toggle;
         if (toggle === false || spsFrame === false) {
             this.jmuxer.feed({
                 video: this.presentFrames[inc][4],
                 duration: spsFrame === true ? (this.fps15 - 2) / this.playbackSpeed : 0
             });
             spsFrame = true;
         }
     }
     return {spsFrame, toggle};
 }

 JmuxPlayer.prototype.handlePlayingForwardPFrame = function (inc) {
     //feed pframes forward
     if (this.mode === 'both') {
         this.jmuxer.feed({
             video: this.presentFrames[inc][4],
             audio: this.presentFrames[inc][5],
         });
     } else {
         this.jmuxer.feed({
             video: this.presentFrames[inc][4],
             duration: 64 / this.playbackSpeed
         });
     }
 }

 JmuxPlayer.prototype.handlePlayingBackwardIFrame = function (inc) {
     if (inc !== 0) {
         if (this.presentFrames[inc - 1][2] === false) {
             this.setTime(this.presentFrames[inc - 1][1], inc);
         }
     }
 }

 JmuxPlayer.prototype.handlePlayingBackwardPFrame = function (inc, iFrameIndex) {
     //this is an attempt to feed pframes backwards, but is extremely choppy
     this.prefeedBackwardsFrames(inc, iFrameIndex, async offsetCount => {
         if (this.mode === 'both') {
             this.jmuxer.feed({
                 video: this.presentFrames[inc][4],
                 audio: this.presentFrames[inc][5],
             });
         } else {
             this.jmuxer.feed({
                 video: this.presentFrames[inc][4],
                 duration: (this.fps15 - 2) / this.playbackSpeed - offsetCount
             });
         }
         setTimeout(() => {
         }, (this.fps15 - 2) / this.playbackSpeed - offsetCount - 5)
     });
}

 JmuxPlayer.prototype.setup_next_segment = function() {
     if ((this.playingForward === true && this.futureFrames[0] !== undefined) || (this.playingForward === false && this.pastFrames[0] !== undefined)) {
         if ((this.playingForward === true && this.futureFrames[0][1] !== undefined) || (this.playingForward === false && this.pastFrames[0][1] !== undefined)) {
             if (this.ws_feeding === false && this.nextSegmentReady === true) {
                 this.clearBuffering();
                 this.catchLastLoop = true;
                 clearInterval(this.feedFramesId);
                 this.feedFramesId = null;
                 this.paused = true;
                 this.setBufferStartTime(this.playingForward === true ? this.futureFrames[0][1] : this.pastFrames[0][1]);
                 //this queues up the next segment in the currently unused array
                 if (this.playingForward === true) {
                     this.pastFrames = this.presentFrames;
                     this.presentFrames = this.futureFrames;
                     this.request_next_segment();
                 } else {
                     this.futureFrames = this.presentFrames;
                     this.presentFrames = this.pastFrames;
                     this.request_previous_segment();
                 }
                 this.setTime(this.playingForward === true ? this.futureFrames[0][1] : this.pastFrames[this.pastFrames.length - 1][1], 0);
             }
         }
     }
 }

JmuxPlayer.prototype.playback_listener = function (evt) {
    PubSub.publish('archiveStreamingDone');
    //evt.currentTarget.that.ws_feeding = false;
    this.ws_feeding = false;
    //clearInterval(evt.currentTarget.that.watch_websocket);
    clearInterval(this.watch_websocket);
    //evt.currentTarget.that.destroyWebSocket(true);
    this.destroyWebSocket(true);
}

JmuxPlayer.prototype.request_next_segment = function (firstIterationOverride) {
    try {
        if (this.presentFrames[this.presentFrames.length - 1][1] + 68 !== undefined) {
            PubSub.publish('request_next_archive_segment', {
                startTime: this.presentFrames[0][1],
                index: this.segmentIndex + 1,
                isContinuous: this.isContinuous,
                previous: false,
                override: firstIterationOverride === true
            });
        }
    } catch (e) {}
}

JmuxPlayer.prototype.request_previous_segment = function (firstIterationOverride) {
    try {
        if (this.presentFrames[this.presentFrames.length - 1][1] + 68 !== undefined) {
            PubSub.publish('request_next_archive_segment', {
                startTime: this.presentFrames[0][1] - 2000,
                index: this.segmentIndex - 1,
                isContinuous: this.isContinuous,
                previous: true,
                override: firstIterationOverride === true
            });
        }
    } catch (e) {}
}

JmuxPlayer.prototype.createSSLiveStream = async function(stream) {
    let ssLiveStream = new SmartSuiteLivestream(this.device);
    await ssLiveStream.connect();
    await ssLiveStream.refresh();
    await ssLiveStream.getLiveStreamRequest(stream, requestId => {
        if (requestId.includes('error')) {
            this.device.setCanvasErrorMessage(this.playerId, requestId, 'Try a different stream quality.');
            this.catchErrorLoop = true;
        }
        this.ws = new WebSocket(stream.streamServer + "videosocket?requestId=" + requestId);
        this.new_jmuxer(stream);
        this.clearBuffering();
    });
}

JmuxPlayer.prototype.setQueueBuffering = function () {
    // this method is used to toggle the buffering on once and stay on when we are waiting for footage to feed in
    // if we used the other method it would spaz out as it would be attempting to restart the animation over and over
    if (this.buffering === false) {
        this.buffering = true;
        PubSub.publish('videoBuffering' + this.playerId, true);
    }
}

JmuxPlayer.prototype.seek = function(time) {
    let video = document.getElementById(this.element)
    video.currentTime = time;
}

JmuxPlayer.prototype.removeLoading = function () {
    if (this.device.getCanvasLoadingStatus(this.playerId) === true) {
        this.device.setCanvasLoadingStatus(this.playerId, false);
    }
    this.device.setCanvasErrorMessage(this.playerId, '', '');
}

JmuxPlayer.prototype.findQuality = function() {
    this.stream = this.device.findQuality(this.device.getStreams(), this.quality, this.device, this.sourceToken, this.dataChannel);
}

JmuxPlayer.prototype.destroy = async function() {
    this.destroyWebSocket();
    await this.destroyJmuxer();
    clearInterval(this.adaptive_stream_interval);
    clearInterval(this.watch_websocket);
    clearInterval(this.feedFramesId);
    this.feedFramesId = null;
    this.paused = true;
    this.livePlay = false;
}

JmuxPlayer.prototype.getCurrentTime = function() {
    return document.getElementById(this.element).currentTime;
}

JmuxPlayer.prototype.start = async function() {
    await this.open_jmuxer(this.stream);
    this.livePlay = true;
}

JmuxPlayer.prototype.start_playback = async function(request, _mode, _isContinuous) {
    this.mode = _mode;
    this.isContinuous = _isContinuous;
    await this.playbackSetUp();
    await this.setup_websocket(request, External);
    this.new_jmuxer(this.stream, 60000, true, 0, _mode === true ? 'both' : 'video', request);
}

JmuxPlayer.prototype.playbackSetUp = async function() {
    await this.destroyJmuxer();
    clearTimeout(this.selfDestructTimeout);
    this.nextSegmentReady = false;
    this.catchLastLoop = true;
    clearInterval(this.retryConnectionInterval);
    this.queuedTimes = {
        past: {startTime: null, endTime: null},
        present: {startTime: null, endTime: null},
        future: {startTime: null, endTime: null}
    };
    this.sdCardFrames = [];
    this.newRequest = true;
    this.firstQueueRequest = true;
    this.firstFutureRequest = true;
    this.firstPastRequest = true;
    this.scrubFramesId = null;
    this.playbackSpeed = 1;
    this.segmentIndex = 0;
    this.retryCount = 0;
    this.errorRetryCount = 0;
    this.paused = false;
    this.catchLastLoop = false;
    await this.setTransitionBuffering();
    this.device.setCanvasErrorMessage(this.playerId, '', '');
}

JmuxPlayer.prototype.setup_websocket = async function (request, _storageLocation) {
    this.ws = null;
    clearInterval(this.feedFramesId);
    this.feedFramesId = null;
    this.paused = true;
    await this.destroyWebSocket();
    this.livePlay = false;
    this.paused = false;
    let url;
    this.storageLocation = _storageLocation;
    if (this.storageLocation === External) {
        url = this.stream.streamServer.replace("mediastream", "archive") + "archiverequest?requestId=" + request.requestId;
    } else if (this.storageLocation === SdCard) {
        url = request.streamServer + 'sdcardstream/sdcardsocket?requestId=' + request.requestId;
    }
    this.ws = new WebSocket(url);
    this.ws.addEventListener('close', e => this.playback_listener(e));
}

JmuxPlayer.prototype.setTransitionBuffering = async function () {
    //this method is used to guarantee the display of the buffering icon when transitioning from live play to archived playback
    if (this.buffering === false) {
        this.buffering = true;
        PubSub.publish('videoBuffering' + this.playerId, true);
    }
}

JmuxPlayer.prototype.next_playback_segment = function(requestId, resetIndexBool) {
    //this one is for continuous playback and does not store any frames
    if (resetIndexBool === true) {
        this.segmentIndex = 0;
    } else if (this.playingForward === true) {
        this.segmentIndex++;
    }
    clearInterval(this.retryConnectionInterval);
    this.queuedTimes.future.startTime = requestId.startTime;
    this.queuedTimes.future.endTime = requestId.endTime;
    let url = this.stream.streamServer.replace("mediastream", "archive") + "archiverequest?requestId=" + requestId.requestId;
    this.ws = new WebSocket(url);
    this.ws.addEventListener('close', e => this.playback_listener(e));
    this.start_playback_listeners(this.mode, this.queuedTimes.future.startTime, this.queuedTimes.future.endTime, 1, requestId.segment);
}

JmuxPlayer.prototype.previous_playback_segment = function(requestId, resetIndexBool) {
    if (resetIndexBool === true) {
        this.segmentIndex = 0;
    } else if (this.playingForward === false){
        this.segmentIndex--;
    }
    this.queuedTimes.past.startTime = requestId.startTime;
    this.queuedTimes.past.endTime = requestId.endTime;
    let url = this.stream.streamServer.replace("mediastream", "archive") + "archiverequest?requestId=" + requestId.requestId;
    this.ws = new WebSocket(url);
    this.ws.addEventListener('close', e => this.playback_listener(e));
    this.start_playback_listeners(this.mode, this.queuedTimes.past.startTime, this.queuedTimes.past.endTime, -1, requestId.segment);
}

JmuxPlayer.prototype.skip_playback_segments = async function(request) {
    await this.setup_websocket(request, External);
    this.start_playback_listeners(this.mode, request.startTime, request.endTime, request.segment);
}

JmuxPlayer.prototype.stop_playback = async function() {
    if (this.livePlay === false) {
        if (this.isArchive !== true) {
            this.findQuality();
            this.ws.removeEventListener('close', this.playback_listener);
            await this.reset_jmuxer();
        } else {
            this.ws.removeEventListener('close', this.playback_listener);
            await this.destroyWebSocket();
        }
    }
    this.livePlay = true;
}

JmuxPlayer.prototype.getSegmentsFailed = function() {
    //if there isn't any following segments after the current ones
    //we clear out the next buffer so the video doesn't loop through the same 20 seconds
    if (this.retryConnectionInterval === null) {
        if (this.playingForward === true) {
            this.futureFrames = [];
        } else {
            this.pastFrames = [];
        }
    }
    clearInterval(this.retryConnectionInterval);
    this.retryConnectionInterval = setInterval(() => {
        this.playingForward === true ? this.request_next_segment(true) : this.request_previous_segment(true);
    }, this.streamTimeoutTimerMS);
}

JmuxPlayer.prototype.isLive = function() {
    return this.livePlay;
}

JmuxPlayer.prototype.isPaused = function() {
    return this.paused;
}

JmuxPlayer.prototype.clearBuffers = function() {
    if (this.playingForward === true) {
        this.futureFrames = [];
        this.nextSegmentReady = true;
    } else {
        this.pastFrames = [];
    }
}

JmuxPlayer.prototype.playback_change_request = function(requestId) {
    this.ws.send('clear');
    setTimeout(() => {
        this.ws.send(requestId);
    }, 500);
}

JmuxPlayer.prototype.startSdPlayback = async function(request) {
    this.sdCardFrameCount = 0;
    await this.playbackSetUp();
    await this.setup_websocket(request, SdCard);
    this.sdCardStartTime = request.startTime * 1000;
    this.new_jmuxer(stream, 60000, true, 0, 'video', request);
}

JmuxPlayer.prototype.setTimeVar = async function(time) {
    await this.pause()
    this.currentTime = time;
}

JmuxPlayer.prototype.pause = function () {
    clearInterval(this.feedFramesId);
    this.previousIFrameIndex = null;
    this.paused = true;
    this.playingForward = true;
    this.playbackSpeed = 1;
    this.feedFramesId = null;
}

JmuxPlayer.prototype.play = function() {
    this.playingForward = true;
    this.paused = false;
    this.previousIFrameIndex = null;
    this.setTime(this.currentTime);
}

JmuxPlayer.prototype.fastForward = function(speedValue) {
    this.playingForward = true;
    this.paused = false;
    this.previousIFrameIndex = null;
    this.playbackSpeed = speedValue;
    this.setTime(this.currentTime);
}

JmuxPlayer.prototype.stopFastForward = function() {
    this.playingForward = true;
    this.paused = false;
    this.previousIFrameIndex = null;
    this.playbackSpeed = 1;
    this.setTime(this.currentTime);
}

JmuxPlayer.prototype.rewind = function() {
    this.playingForward = false;
    this.paused = false;
    this.previousIFrameIndex = null;
    this.playbackSpeed = 1;
    this.setTime(this.currentTime);
}

JmuxPlayer.prototype.stopRewind = function() {
    this.playingForward = true;
    this.paused = false;
    this.previousIFrameIndex = null;
    this.setTime(this.currentTime);
}

JmuxPlayer.prototype.getTime = function() {
    return this.currentTime;
}

JmuxPlayer.prototype.getFrameBuffer = function() {
    return this.currentFrameBufferTime;
}

JmuxPlayer.prototype.clearFrameBuffer = async function() {
    await this.destroyWebSocket();
    this.currentFrameBufferTime = null;
}

JmuxPlayer.prototype.adjustPlaybackSpeed = function(time) {
    let video = document.getElementById(this.element)
    video.playbackRate = time;
}

JmuxPlayer.prototype.getJmuxer = function() {
    return this;
}

JmuxPlayer.prototype.feed = async function(arg) {
    await this.jmuxer.feed(arg)
}

JmuxPlayer.prototype.setBufferStartTime = function (time) {
}

export default JmuxPlayer;
