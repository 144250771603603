// Views - Components
const Tabs = () => import('@/views/template_files/base/Tabs');
const Breadcrumbs = () => import('@/views/template_files/base/Breadcrumbs');
const Navs = () => import('@/views/template_files/base/Navs');
const Navbars = () => import('@/views/template_files/base/Navbars');

const Colors = () => import('@/views/template_files/theme/Colors');
const Typography = () => import('@/views/template_files/theme/Typography');

const Charts = () => import('@/views/template_files/Charts');
const Widgets = () => import('@/views/template_files/Widgets');

// Plugins
const Draggable = () => import('@/views/template_files/plugins/Draggable');
const Calendar = () => import('@/views/template_files/plugins/Calendar');
const Spinners = () => import('@/views/template_files/plugins/Spinners');

// Views - UI Kits
const Invoice = () => import('@/views/template_files/apps/invoicing/Invoice');
const Compose = () => import('@/views/template_files/apps/email/Compose');
const Inbox = () => import('@/views/template_files/apps/email/Inbox');
const Message = () => import('@/views/template_files/apps/email/Message');

export default [
    {
        path: 'theme',
        redirect: '/theme/colors',
        name: 'Theme',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'colors',
                name: 'Colors',
                component: Colors
            },
            {
                path: 'typography',
                name: 'Typography',
                component: Typography
            }
        ]
    },
    {
        path: 'charts',
        name: 'Charts',
        component: Charts
    },
    {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
    },
    {
        path: 'base',
        redirect: '/base/cards',
        name: 'Base',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'breadcrumbs',
                name: 'Breadcrumbs',
                component: Breadcrumbs
            },
            {
                path: 'navs',
                name: 'Navs',
                component: Navs
            },
            {
                path: 'navbars',
                name: 'Navbars',
                component: Navbars
            },
            {
                path: 'tabs',
                name: 'Tabs',
                component: Tabs
            }
        ]
    },
    {
        path: 'plugins',
        redirect: '/plugins/draggable',
        name: 'Plugins',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'draggable',
                name: 'Draggable Cards',
                component: Draggable
            },
            {
                path: 'calendar',
                name: 'Calendar',
                component: Calendar
            },
            {
                path: 'spinners',
                name: 'Spinners',
                component: Spinners
            },
        ]
    },
    {
        path: 'apps',
        name: 'Apps',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'invoicing',
                redirect: '/apps/invoicing/invoice',
                name: 'Invoicing',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'invoice',
                        name: 'Invoice',
                        component: Invoice
                    }
                ]
            },
            {
                path: 'email',
                redirect: '/apps/email/inbox',
                name: 'Email',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'compose',
                        name: 'Compose',
                        component: Compose
                    },
                    {
                        path: 'inbox',
                        name: 'Inbox',
                        component: Inbox
                    },
                    {
                        path: 'message',
                        name: 'Message',
                        component: Message
                    }
                ]
            }
        ]
    }
];