import Vue from 'vue';
import Router from 'vue-router';

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer');

// Views
const Dashboard = () => import('@/views/template_files/Dashboard');

// Views - Admin
import adminRoutes from '@/router/admin_routes';

// Views - Template
import templateRoutes from '@/router/template_routes';

// Views - Expansion Modules
import expansionModuleRoutes from '@/router/expansion_modules_routes';

// Views - RISC
import riscRoutes from '@/router/expansion_modules_routes/risc_routes';

// Views - Report
import reportRoutes from '@/router/expansion_modules_routes/report_routes';
import authStore from "@/store/authStore";

// Views - Pages
const Page404 = () => import('@/views/template_files/pages/Page404');
const Page500 = () => import('@/views/template_files/pages/Page500');
const Login_New = () => import('@/views/admin/Login_New');
const Register = () => import('@/views/template_files/pages/Register');
const PasswordReset = () => import('@/views/admin/PasswordReset');

// Views - Video Wall
const VideoWall = () => import('@/views/video_wall/VideoWall');

const Exports = () => import('@/views/Exports');
const DeviceManager = () => import('@/views/DeviceManager/DeviceManager');

// Views - DIS
const DISDisplay = () => import('@/views/expansion_modules/dis/dis_display');

// Views - DIS
const FidsBaggage = () => import('@/views/expansion_modules/fids/fids_baggage');
const FidsOverview = () => import('@/views/expansion_modules/fids/fids_overview');
const FidsGate = () => import('@/views/expansion_modules/fids/fids_gate');

// Test
const Archives = () => import('@/views/VideoArchives');
const SharedStream = () => import('@/views/shared_services/SharedStream');
const StreamSharing = () => import('@/views/admin/user_management/StreamSharing');

Vue.use(Router);

const defaultContainer = {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: DefaultContainer,
    children: [
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard
        },
        {
            path: '/video-wall',
            name: 'Video Wall',
            component: VideoWall,
            //beforeEnter: authStore.getters.requireAuth,
            props: true
        },
        {
            path: '/exports',
            name: 'Exports',
            component: Exports
        },
        {
            path: '/devices',
            name: 'Device Manager',
            component: DeviceManager,
            //beforeEnter: requireAuth,
            props: true
        },
        {
            path: '/archives',
            name: 'Archives',
            component: Archives
        },
        {
            path: '/streamsharing',
            name: 'Stream Sharing',
            component: StreamSharing
        },
    ]
};

defaultContainer.children = defaultContainer.children.concat(adminRoutes); // Adding admin routes
defaultContainer.children = defaultContainer.children.concat(templateRoutes); // Adding template routes
defaultContainer.children = defaultContainer.children.concat(expansionModuleRoutes); // Adding expansion module routes
defaultContainer.children = defaultContainer.children.concat(riscRoutes); // Adding risc routes
defaultContainer.children = defaultContainer.children.concat(reportRoutes); // Adding report routes

function setCallbackUrl(url) {
    //TODO Move this to store
    localStorage.setItem('callback', window.origin + "/#" + url);
}

const baseRoutes = [
    {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'display/:conId/:devId',
                name: 'DIS Display',
                component: DISDisplay,
                props: true,
            },
            {
                path: '500',
                name: 'Page500',
                component: Page500
            },
            {
                path: 'login',
                name: 'Login',
                //component: Login
                component: Login_New,
                beforeEnter: setCallbackUrl('/dashboard')
            },
            {
                path: 'register',
                name: 'Register',
                component: Register
            },
            {
                path: '/sharedstream',
                name: 'Shared Stream',
                component: SharedStream,
                props: true
            }
        ]
    },
    {
        path: '/passwordreset',
        name: 'PasswordReset',
        beforeEnter: (to, from, next) => {
            if(to.query.tokenId !== undefined) {
                iss.authVerify(to.query.tokenId).then(response => {
                    if(response.status === 200) {
                        next();
                    } else {
                        window.location = '/#/pages/login';
                        location.reload();
                    }
                }).catch(() => {
                    window.location = '/#/pages/login';
                    location.reload();
                });
            } else {
                window.location = '/#/pages/login';
                location.reload();
            }
        },
        component: PasswordReset
    },
    {
        path: '/fids/',
        name: 'FIDS',
        component: FidsOverview
    },
    {
        path: '/fids/gate/:id',
        name: 'Gate',
        component: FidsGate
    },
    {
        path: '/fids/baggage/:id',
        name: 'Baggage',
        component: FidsBaggage
    },
    {
        path: '*',
        name: 'Page404',
        component: Page404
    }
];

const routes = baseRoutes.concat([defaultContainer]);

const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes
});

router.beforeEach(async (to, from, next) => {
    await authStore.dispatch("requireAuth", {to: to, from: from, next: next});
});

/*router.beforeEach((to, from, next) => {
    if (to.query.teapoting !== 'true' && !String(window.location.href).includes('/pages/login') && to.path !== '/pages/login' && to.path !== '/passwordreset') {
        axios({
            method: "POST",
            "url": process.env.VUE_APP_ISSENGINE + "/auth/verify",
            "headers": {"content-type": "application/json", "Authorization": "JWT " + localStorage.mtopToken}
        }).then(() => {
            if (to.name !== "Page404") {
                axios({
                    method: "POST",
                    "url": process.env.VUE_APP_FCRENGINE + '/permission/login',
                    "data": {"url": ((to.matched[to.matched.length - 1] || to).path)},
                    "headers": {"content-type": "application/json", "Authorization": "JWT " + localStorage.mtopToken}
                }).then(() => {
                    next();
                }).catch(() => {
                    next('/')
                })
            } else {
                next();
            }
        }, error => {
            if (error.response.status === 418) {
                router.push({path: ((to.matched[to.matched.length - 1] || to).path), query: {teapoting: 'true'}})
            } else if (!String(window.location.href).includes('/pages/login')) {
                window.location = '/#/pages/login';
                location.reload();
            }
        });
    } else {
        next()
    }
});*/

/*router.beforeEach((to, from, next) => {
    if (to.query.teapoting !== 'true' && !String(window.location.href).includes('/pages/login') && to.path !== '/pages/login') {
        if (to.name !== "Page404") {
            axios({
                method: "POST",
                "url": process.env.VUE_APP_FCRENGINE + '/permission/login',
                "data": {"url": ((to.matched[to.matched.length - 1] || to).path)},
                "headers": {"content-type": "application/json", "Authorization": "JWT " + localStorage.mtopToken}
            }).then(() => {
                next();
            }, error => {
                if (error.response.status === 418) {
                    router.push({path: ((to.matched[to.matched.length - 1] || to).path), query: {teapoting: 'true'}})
                } else if(error.response.status === 401 && to.path !== '/') {
                    next('/');
                } else if(error.response.status === 401 && to.path === '/') {
                    window.location = '/#/pages/login';
                    location.reload();
                } else if (!String(window.location.href).includes('/pages/login')) {
                    /!*this.$mToast({
                      title: error.response.status + ' Error',
                      text: "Not authorized token: " + error.response.statusText,
                      style: 'error'
                    });*!/
                    window.location = '/#/pages/login';
                    location.reload();
                } else {
                    window.location = '/#/pages/login';
                    location.reload();
                }
            }).catch(() => {
                next('/')
            })
        } else {
            next();
        }
    } else {
        next()
    }
});*/

export default router
