import {UserManager, WebStorageStateStore, User} from 'oidc-client';
import router from '@/router/router'
import Axios from 'axios'

//var risckeeperBasePath = get_authority()
let navigate = router
let url = "https://satellite.monteltech.com"  // Use This for production
//let url = "http://localhost:8080"               // Use This for testing
let userManager;
let settings;
/*let user_manager_interval = setInterval(function () {
    try {
        if (JSON.parse(localStorage.getItem("customFields"))) {
            if (JSON.parse(localStorage.getItem("customFields")) !== null) {
                make_userManager();
            }
            clearInterval(user_manager_interval);
        }
    } catch (error) {
        console.error(error);
    }
}, 1000);*/

async function make_userManager() {
    settings = {
        userStore: new WebStorageStateStore({store: window.localStorage}),
        authority: get_authority(),
        client_id: get_clientId(),
        redirect_uri: get_redirectUri(),
        automaticSilentRenew: true,
        revokeAccessTokenOnSignout: true,
        silent_redirect_uri: get_silentRedirectUri(),
        response_type: 'code',
        scope: 'openid profile riscengine riscstorage',
        post_logout_redirect_uri: get_logoutRedirectUri(),
        filterProtocolClaims: true
    };
    userManager = await new UserManager(settings);
}

function get_authority() {
    return JSON.parse(localStorage.getItem("customFields")).rk_authority || null;
}

function get_clientId() {
    //return JSON.parse(localStorage.getItem("customFields")).rk_clientId || null;
    return 'riscsat';    // Use This for production
    //return 'riscsatlocal';  // Use This for testing
}

function get_redirectUri() {
    //return JSON.parse(localStorage.getItem("customFields")).rk_redirectUri || null;
    return url + '/callback.html';
}

function get_silentRedirectUri() {
    //return JSON.parse(localStorage.getItem("customFields")).rk_silentRedirectUri || null;
    return url + '/silent-renew.html';
}

function get_logoutRedirectUri() {
    //return JSON.parse(localStorage.getItem("customFields")).rk_logoutRedirectUri || null;
    return  url + '/';
}

export function login() {
    clearUser()
    Axios.get(get_authority() + '/.well-known/openid-configuration')
        .then(function (response) {
            if (response.status === 200) {
                return userManager.signinRedirect()
            } else {
                navigate.push('/')
            }

        })
        .catch(function (error) {
            console.error(error)
            navigate.push('/')
        })

}

export function logout() {
    return userManager.signoutRedirect()
}

export function clearUser() {
    userManager.removeUser()
    userManager.clearStaleState()
}

export function clearIncompleteState() {
    userManager.clearStaleState()
}

function setCallbackUrl(to) {
    localStorage.setItem('callback', window.origin + "/#" + to.fullPath);
}

export function requireAuth(to, from, next) {
    async function proceed() {
        if(userManager === undefined || userManager === null) {
            await make_userManager();
        }
        Axios.get(get_authority() + '/.well-known/openid-configuration')
            .then(async function (response) {
                if (response.status === 200) {
                    if (userManager !== undefined) {
                        const user = await userManager.getUser();
                        if (user !== null && user.scope.includes("riscengine") && user.scope.includes("riscstorage")) {
                            if (user.access_token !== null && user.expired === false) {
                                setCallbackUrl(to);
                                next();
                            } else {
                                setCallbackUrl(to);
                                next(userManager.signinRedirect());
                            }
                        } else {
                            setCallbackUrl(to);
                            next(userManager.signinRedirect());
                        }
                    } else {
                        setCallbackUrl(to);
                        await make_userManager();
                        await userManager.signinRedirect();
                    }
                } else {
                    console.warn('RiscKeeper Unreachable')
                    clearUser();
                    await navigate.push('/')
                }

            })
            .catch(function (error) {
                console.error(error)
                clearUser();
                navigate.push('/')
            })
    }
    proceed();
}

export function getUserInfo() {
    return new Promise((resolve, reject) => {
        userManager.getUser()
            .then(user => {
                resolve(user)
            })
            .catch(error => reject(error))
    })
}

export function getIdTokenAsync() {
    return new Promise((resolve, reject) => {
        userManager.getUser()
            .then(user => {
                resolve(user.id_token)
            })
            .catch(error => reject(error))
    })
}

export function getAccessToken() {
    let user = JSON.parse(localStorage.getItem(`oidc.user:${settings.authority}:${settings.client_id}`));
    return user === null ? null : user.access_token;
}

export function getAccessTokenAsync() {
    return new Promise((resolve, reject) =>{
        userManager.getUser()
            .then(user => {
                resolve(user.access_token)
            })
            .catch(error => reject(error))
    });
}